import React from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './App.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";


const HomePage = ( { formState, changeFormState } ) => {
 
  //const history = useHistory();

  const submitSignUp = () => {
    const body = {
      "displayname": formState.displayname,
      "youtubechannelurl": formState.youtubechannelurl,
      "wanttobeinvideo": true,
      "captchatoken": formState.captchatoken
    };
    axios({
      method: 'POST',
      //url: 'https://evidence.report' + ':' + '3003' + '/',
      url: 'https://evidence.report:3003/',
      timeout: 5000,
      data: body,
    }).then((resp) => {
        if (resp.data.success) {
          changeFormState(true, "submitted");
        } else {
          alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        }
      })
      .catch(err => {
        alert("Something went wrong! Please contact Khalid at: KhalidShahinIP@gmail.com");
        console.log(err);
      });
  }

  const onCaptchaChange = (value) => {
    changeFormState(value, "captchatoken");
  }
  
  return (
    <Grid>
    <Grid.Column align="center">
    <div>
      <header className="App-header" style={{textAlign: "center"}}><h1 style={{fontSize: "36px", paddingBottom: "0px"}}>Evidence.Report</h1></header>
        <>
          <Segment className={`containerSegment`} color="violet" raised>
            <p>GitHub: <a href="https://github.com/Khalid-Shahin">github.com/Khalid-Shahin</a></p>
            <div style={{textAlign: "left"}}>
              <p>Summary of Recent Projects</p>
              <ul>
                <li>
                  <p>Programming</p>
                  <ul>
                    <li><p>Full-stack developer</p></li>
                    <li><p>Creation of scripts and data entry forms</p></li>
                    <li>Creation of programs that use the National Library of Medicine APIs (PubMed, RxNav, etc)</li>
                  </ul>
                </li>
                <li>FHIR<sup>&reg;</sup>
                  <ul>
                    <li>Evidence-Based Resources (Evidence, EvidenceVariable, Statistic, Citation, EvidenceReport)</li>
                    <li>FHIR<sup>&reg;</sup> Committer</li>
                  </ul>
                </li>
                <li>Processing of evidence-based data and statistics</li>
              </ul>
              <p>Learn more about EBMonFHIR: <a href="https://confluence.hl7.org/display/CDS/EBMonFHIR">https://confluence.hl7.org/display/CDS/EBMonFHIR</a></p>
            </div>
            <br/>
            <b>Email:</b> <a href="mailto:khalid@evidence.report">khalid@evidence.report</a>
          </Segment>

          <Segment className={`containerSegment`} color='green' raised> 
            <h1>Projects</h1>
            <br />
            <div style={{width: "360px"}}>
              <Link to="/wordchaser"><Button variant="contained" color="primary" size="large" style={{width: "100%"}} onClick={() => { }}>
                <h3>Word Chaser</h3></Button>
              </Link>
              <br/><br/>
              <Link to="/gravity"><Button variant="contained" color="primary" size="large" style={{width: "100%"}} onClick={() => { }}>
                <h3>Simple Gravity Simulation</h3></Button>
              </Link>
              <br/><br/>
            </div>
          </Segment>
          <br className={`unselectable`}/>
          <div className={`bottomSegment`}>
            {/*<Link to="/"><Segment className={`bottomSegment unselectable`} inverted raised onClick={() => { }}>
              <h2>Back</h2>
            </Segment></Link>*/}
          </div>
        </>
      </div>
    </Grid.Column>
  </Grid>
  );
};

  export default HomePage;