import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import HomePage from './HomePage';
import WordChaserPage from './WordChaserPage';
import PageNotFoundPage from './PageNotFoundPage';
import ScrollToTop from './ScrollToTop';
import GravityPage from './GravityPage';

//let serveraddress = "http://localhost";
//let serveraddress = "https://evidence.report";

const App = ( ) => {

  const startingFormState = {
    "displayname": "",
    "youtubechannelurl": "",
    "wanttobeinvideo": false,
    "submitted": false,
    "captchatoken": ""
   };

  const [formState, setFormState] = useState(startingFormState);


  const changeFormState = (newValue, field) => {
   setFormState(prevState  => {
     return {
       ...prevState,
       [field]: newValue,
     };
   });
  };
  
  return (
    <Router>
    <ScrollToTop />
    <Switch>
      <Route path={["/word", "/wordchaser"]} exact>
        <div className="App" style={{backgroundImage: `url("/webpagebackground2.jpg")`, padding: "0px", height: "100%", overflowX: "hidden", overflowY: "hidden"}}>
          <div style={{padding: "12px 0px 0px 0px", height: "100%"}}>
            <WordChaserPage />
          </div>
        </div>
      </Route>
      <Route path={["/gravity"]} exact>
        <GravityPage />
      </Route>
      <Route>
        <main className="App" style={{backgroundImage: `url("/webpagebackground2.jpg")`, padding: "0px"}}>
          <div style={{padding: "12px 0px 0px 0px"}}>
            <Switch>
              <Route path={[""]} exact>
                <HomePage formState={formState} changeFormState={changeFormState} />
              </Route>
              {/*
              <Route path={["/worddesktop"]} exact>
                <WordChaserPage />
              </Route>*/}
              <Route>
                <PageNotFoundPage />
              </Route>
            </Switch>
              <br className={`unselectable`}/>
              <br className={`unselectable`}/>
              <br className={`unselectable`}/>
              <br className={`unselectable`}/>
              <br className={`unselectable`}/>
            <footer><div><span className={"unselectable"}>Contact Khalid Shahin: </span><a style={{color: "#9090FF"}} href="mailto:khalid@evidence.report">khalid@evidence.report</a></div></footer>
            { /* <div className={"footerClass unselectable"}><div style={{width: "100%"}}>KhalidSMShahin</div><div></div></div> */ }
          </div>
        </main>
      </Route>
    </Switch>
    </Router>
  );
};


export default App;
